var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"justify-center d-flex"},[_c('div',{staticClass:"justify-center d-flex flex-column",staticStyle:{"max-width":"1000px","width":"100%"}},[_c('div',{staticClass:"d-flex flex-column w-100 position-relative"},[(_vm.agency)?_c('v-img',{staticStyle:{"flex-grow":"1"},attrs:{"src":_vm.profile && _vm.agency && _vm.profile._id === _vm.agency._id
            ? _vm.profile.bgImage || require('@/assets/background0.png')
            : _vm.agency?.bgImage || require('@/assets/background0.png'),"width":"100%","aspect-ratio":_vm.$vuetify.breakpoint.smAndDown ? 414 / 180 : 1654 / 455}}):_vm._e(),_c('div',{staticClass:"hidden-sm-and-down",staticStyle:{"padding-left":"300px","background-color":"#eee4ff"}},[_c('v-tabs',{attrs:{"background-color":"#eee4ff"},on:{"change":_vm.onSelected},model:{value:(_vm.selectedPage),callback:function ($$v) {_vm.selectedPage=$$v},expression:"selectedPage"}},_vm._l((_vm.menus),function(item){return _c('v-tab',{key:item.title},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)],1),(_vm.agency)?_c('div',{staticClass:"hidden-md-and-up",staticStyle:{"background-color":"#eee4ff"},style:(_vm.$vuetify.breakpoint.xs && _vm.profile._id != _vm.agency._id
            ? 'margin-top: 100px;'
            : 'margin-top: 60px;')},[_c('v-tabs',{attrs:{"background-color":"#eee4ff","grow":""},on:{"change":_vm.onSelected},model:{value:(_vm.selectedPage),callback:function ($$v) {_vm.selectedPage=$$v},expression:"selectedPage"}},_vm._l((_vm.menus),function(item){return _c('v-tab',{key:item.title},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)],1):_vm._e(),_c('div',{staticClass:"cursor-pointer",on:{"click":_vm.onEditTitle}},[(_vm.agency)?_c('agency-avatar',{staticStyle:{"position":"absolute"},style:(_vm.$vuetify.breakpoint.xs && _vm.profile._id != _vm.agency._id
              ? 'bottom: 110px; left: 50%; transform: translate(-50%,0)'
              : _vm.$vuetify.breakpoint.xs && _vm.profile._id == _vm.agency._id
              ? 'bottom: 60px; left: 50%; transform: translate(-50%,0)'
              : _vm.$vuetify.breakpoint.sm
              ? 'bottom: 60px; left: 40px;'
              : 'bottom: 0px; left: 40px;'),attrs:{"img":_vm.agency.midThumbnail ? _vm.agency.midThumbnail : _vm.agency.image,"size":_vm.$vuetify.breakpoint.xs ? 140 : 180,"name":_vm.agency.name,"onImageSelected":_vm.onImageSelected}}):_vm._e()],1),(_vm.getCompletedInfo && _vm.agency?.id && _vm.profile?.id)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"position":"absolute","left":"190px","bottom":"150px"},attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,false,2280492743)},[_c('span',[_vm._v("Incompleted profile: Missing "+_vm._s(_vm.getCompletedInfo))])]):_vm._e(),(_vm.profile.type === 'Agency' && _vm.profile._id === _vm.agency._id)?_c('circle-icon-button',{staticStyle:{"position":"absolute","top":"277px","right":"20px"},attrs:{"text":"Download Needs Report","icon":"mdi mdi-download","onClicked":_vm.downloadNeedsReport,"loading":_vm.loading,"disabled":_vm.loading}}):_vm._e(),(_vm.agency && _vm.agency._id == _vm.profile._id)?_c('circle-icon-button',{staticStyle:{"position":"absolute","top":"20px","right":"20px"},attrs:{"text":"Edit Background","icon":"mdi-image-edit","onClicked":_vm.onChangeBackground}}):_vm._e(),_c('div',{staticStyle:{"position":"absolute","bottom":"60px"},style:((_vm.agency && _vm.agency._id != _vm.profile._id && _vm.profileType != 'Admin'
            ? 'width: 298px;'
            : 'width: 160px;') +
            (_vm.$vuetify.breakpoint.xs
              ? 'left: 50%; transform: translate(-50%,0)'
              : 'right: 30px;'))},[(_vm.agency && _vm.agency._id != _vm.profile._id && _vm.profileType != 'Admin')?_c('v-btn',{attrs:{"color":"#B5E539","loading":_vm.becomingFan},on:{"click":_vm.becameFan}},[_vm._v(_vm._s(_vm.getFanned ? "Unfollow" : "Follow")+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"size":"16"}},[_vm._v(_vm._s(_vm.getFanned ? "mdi-account-check" : "mdi-account-plus"))])],1):_vm._e(),(_vm.agency && _vm.agency._id != _vm.profile._id)?_c('v-btn',{staticClass:"ml-5",attrs:{"color":"#B5E539"},on:{"click":_vm.goToMessage}},[_vm._v("Message "),_c('v-icon',{staticClass:"ml-2",attrs:{"size":"16"}},[_vm._v("mdi-message")])],1):_vm._e()],1)],1),_c('router-view')],1),_c('agency-profile-edit-background',{attrs:{"dialog":_vm.bgDialog,"onClose":_vm.closeBGDialog}}),(_vm.editTitleDialog)?_c('agency-profile-edit-title',{attrs:{"dialog":_vm.editTitleDialog,"onClose":_vm.onCloseEditDialog}}):_vm._e(),_c('v-snackbar',{model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }